<template>
  <div>
    <campaign-simple-list></campaign-simple-list>
  </div>
</template>

<script>
import CampaignSimpleList from './../../../components/bulksms/campaigns/simple-list';
export default {
  data: () => ({
  }),
  components: {
    CampaignSimpleList
  }
}
</script>