var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-start px-4 pt-3 pb-2 flex-wrap"},[_c('v-btn',{staticClass:"mr-2 mb-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("btn.refresh"))+" ")],1),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"depressed":"","loading":_vm.loading.filter},on:{"click":function($event){return _vm.getFilter()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t("btn.filter"))+" ")],1),_c('div',{staticClass:"mb-2",class:{'mr-auto': _vm.$vuetify.breakpoint.xsOnly, 'ml-auto': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'bulksms' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-plus-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t("bulksms.campaign.simple.btn.create")))])],1)],1)],1)]),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("bulksms.campaign.simple.list.title"))+" ")])]),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.meta.total},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"150px"}},[_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")))])]),_c('td',{staticStyle:{"min-width":"100px"}},[_vm._v(" "+_vm._s(item.sender)+" ")]),_c('td',{attrs:{"title":item.content}},[_vm._v(" "+_vm._s(_vm.$utils.truncate(item.content, [160, 30]))+" ")]),_c('td',[(item.status === 'INIT')?[(_vm.isScheduledPush(item.createdAt, item.sendDate))?_c('v-chip',{staticClass:"table-chips text-body-2",attrs:{"color":"purple darken-4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"white--text"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$t("bulksms.campaign.fields.status.scheduled")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( "bulksms.campaign.fields.send_date.scheduled", _vm.$moment(item.sendDate).format("YYYY-MM-DD"), _vm.$moment(item.sendDate).format("HH:mm:ss") )))])])],1):_c('v-chip',{staticClass:"table-chips text-body-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("bulksms.campaign.fields.status.init")))])])]:(item.status === 'RUNNING')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"indigo darken-4"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("bulksms.campaign.fields.status.running")))])]):(item.status === 'TRAITED')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"success"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("bulksms.campaign.fields.status.treated")))])]):_c('v-chip',{staticClass:"table-chips",attrs:{"color":"secondary"}},[_c('span',{staticClass:"white--text"},[_vm._v("-")])])],2),_c('td',[_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_c('span',[_vm._v(_vm._s(_vm.$utils.pad(item.totalNumber, 2)))])])],1),_c('td',[(item.status !== 'INIT')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_c('span',[(item.lastTreated <= item.totalNumber)?_c('span',[_vm._v(_vm._s(_vm.$utils.pad(item.totalNumber - item.totalNotSend, 2)))]):_c('span',[_vm._v(_vm._s(item.lastTreated))])])]):_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_vm._v(" - ")])],1),_c('td',[(item.status !== 'INIT')?_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_c('span',[_vm._v(_vm._s(_vm.$utils.pad(item.totalNotSend, 2)))])]):_c('v-chip',{staticClass:"table-chips",attrs:{"color":"fourth"}},[_vm._v(" - ")])],1),_c('td',{staticStyle:{"min-width":"130px"}},[[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.getCampaign(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("btn.display.description")))])])],(item.status === 'TRAITED')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"ml-2",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.getCampaignDetails(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-text-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("bulksms.campaign.simple.btn.detail")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('campaign-simple-view',{attrs:{"dialog":_vm.dialog.view,"campaign":_vm.campaign.display}}),_c('campaign-simple-detail',{attrs:{"dialog":_vm.dialog.detail,"campaign":_vm.campaign.details}}),_c('campaign-simple-filter',{attrs:{"dialog":_vm.dialog.filter},on:{"filter":_vm.applyFilter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }