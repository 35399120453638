<template>
  <v-dialog fullscreen v-model="dialog.display">
    <v-card color="fourth">
      <v-toolbar elevation="0" rounded="0" color="white">
        <v-btn class="mr-2" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.campaign.simple.detail.title")
          }}</span>
        </span>
      </v-toolbar>
      <v-card-text class="pt-10">
        <div class="d-flex align-center justify-center">
          <div style="width: 800px" v-if="!dialog.loading">
            <div class="d-flex justify-end">
              <div>
                <v-text-field
                  style="width: 220px"
                  dense
                  append-icon="mdi-magnify"
                  :label="$t('bulksms.campaign.fields.numbers.search')"
                  class="font-weight-bold"
                  @click:append="filter()"
                  @keyup.enter.prevent="filter"
                  v-model="filters.phone"
                  clearable
                ></v-text-field>
              </div>
            </div>
            <v-card class="mb-2">
              <v-card-title class="px-4 pb-0">
                <span class="font-weight-bold text-body-1">{{
                  $t("bulksms.campaign.fields.content.title2")
                }}</span>
              </v-card-title>
              <v-card-text class="mt-0 pt-2">{{
                campaign.content
              }}</v-card-text>
            </v-card>
            <v-card>
              <v-data-table
                class="elevation-0 datatable"
                :headers="headers"
                :items="items"
                :footer-props="footerProps"
              >
                <template v-slot:header.name="{ header }">
                  <span>{{ $t(header.text) }}</span>
                </template>
                <template v-slot:item.sender>
                  <span>{{ campaign.sender }}</span>
                </template>
                <template v-slot:item.error="{ item }">
                  <v-chip
                    color="success"
                    class="table-chips text-body-2"
                    v-if="!item.error"
                    >{{ $t("bulksms.campaign.fields.status.treated") }}</v-chip
                  >
                  <v-chip
                    color="error"
                    class="table-chips text-body-2"
                    v-else
                    >{{ item.error }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card>
          </div>
          <div v-else>
            <div class="text-center mt-5">
              <v-progress-circular
                indeterminate
                color="secondary"
                width="3"
                size="50"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          loading: false,
        };
      },
    },
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    headers: [],
    items: [],
    meta: {
      total: 50,
    },
    itemsPerPage: 20,
    footerProps: {
      itemsPerPageOptions: [20, 100, 500],
      showFirstLastPage: true,
    },
    filters: {
      phone: null,
    },
  }),
  watch: {
    "dialog.loading"(val) {
      if (!val) {
        this.setHeaders();
        this.setItems();
      }
    },
  },
  methods: {
    setHeaders() {
      const headers = [
        {
          text: this.$t("bulksms.campaign.fields.numbers.title2"),
          value: "phone",
          align: "start",
          sortable: true,
          width: 100,
        },
        {
          text: this.$t("bulksms.campaign.fields.sender.title2"),
          value: "sender",
          align: "start",
          sortable: false,
          width: 100,
        },
        {
          text: this.$t("bulksms.campaign.fields.status.title"),
          value: "error",
          align: "error",
          sortable: false,
          width: 100,
        },
      ];
      this.headers = headers;
    },
    setItems() {
      const items = [];
      if (Array.isArray(this.campaign.numbersNotSend)) {
        this._.each(this.campaign.numbersNotSend, (wrong, k) => {
          if (this.filters.phone && wrong.number !== this.filters.phone) return;
          items.push({
            id: "wrong-" + k,
            phone: wrong.number,
            error: wrong.error,
            message: null,
          });
        });
      }
      
      if (Array.isArray(this.campaign.numbers)) {
        this._.each(this.campaign.numbers, (number, k) => {
          if (this.filters.phone && number !== this.filters.phone) return;
          items.push({
            id: "good-" + k,
            error: null,
            phone: number,
            message: null,
          });
        });
      }
      this.items = items;
    },
    filter() {
      this.setItems();
    },
  },
};
</script>