<template>
  <div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 pt-3 pb-2 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="getFilter()" class="mb-2 mr-2" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <div :class="{'mr-auto': $vuetify.breakpoint.xsOnly, 'ml-auto': $vuetify.breakpoint.smAndUp }" class="mb-2">
          <v-btn color="primary" :to="{ name: 'bulksms' }">
            <v-icon left>mdi-email-plus-outline</v-icon>
            <span>{{ $t("bulksms.campaign.simple.btn.create") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">
          {{ $t("bulksms.campaign.simple.list.title") }}
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :server-items-length="meta.total"
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot:default="{ hover }">
                <tr>
                  <td style="min-width: 150px">
                    <span>{{
                      $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td style="min-width: 100px">
                    {{ item.sender }}
                  </td>
                  <td :title="item.content">
                    {{ $utils.truncate(item.content, [160, 30]) }}
                  </td>
                  <td>
                    <template v-if="item.status === 'INIT'">
                      <v-chip
                        color="purple darken-4"
                        v-if="isScheduledPush(item.createdAt, item.sendDate)"
                        class="table-chips text-body-2"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                              >{{
                                $t("bulksms.campaign.fields.status.scheduled")
                              }}</span
                            >
                          </template>
                          <span>{{
                            $t(
                              "bulksms.campaign.fields.send_date.scheduled",
                              $moment(item.sendDate).format("YYYY-MM-DD"),
                              $moment(item.sendDate).format("HH:mm:ss")
                            )
                          }}</span>
                        </v-tooltip>
                      </v-chip>
                      <v-chip
                        color="secondary"
                        v-else
                        class="table-chips text-body-2"
                      >
                        <span class="white--text">{{
                          $t("bulksms.campaign.fields.status.init")
                        }}</span>
                      </v-chip>
                    </template>
                    <v-chip
                      color="indigo darken-4"
                      class="table-chips"
                      v-else-if="item.status === 'RUNNING'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.running")
                      }}</span>
                    </v-chip>
                    <v-chip
                      color="success"
                      class="table-chips"
                      v-else-if="item.status === 'TRAITED'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.campaign.fields.status.treated")
                      }}</span>
                    </v-chip>
                    <v-chip color="secondary" class="table-chips" v-else>
                      <span class="white--text">-</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip color="fourth" class="table-chips">
                      <span>{{ $utils.pad(item.totalNumber, 2) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip
                      color="fourth"
                      v-if="item.status !== 'INIT'"
                      class="table-chips"
                    >
                      <span>
                        <span v-if="item.lastTreated <= item.totalNumber">{{
                          $utils.pad(item.totalNumber - item.totalNotSend, 2)
                        }}</span>
                        <span v-else>{{ item.lastTreated }}</span>
                      </span>
                    </v-chip>
                    <v-chip color="fourth" v-else class="table-chips">
                      -
                    </v-chip>
                  </td>
                  <td>
                    <v-chip
                      color="fourth"
                      v-if="item.status !== 'INIT'"
                      class="table-chips"
                    >
                      <span>{{ $utils.pad(item.totalNotSend, 2) }}</span>
                    </v-chip>
                    <v-chip color="fourth" v-else class="table-chips">
                      -
                    </v-chip>
                  </td>
                  <td style="min-width: 130px">
                    <template>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="getCampaign(item.id)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary">mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("btn.display.description") }}</span>
                      </v-tooltip>
                    </template>
                    <template v-if="item.status === 'TRAITED'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="getCampaignDetails(item.id)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="ml-2"
                          >
                            <v-icon color="primary"
                              >mdi-text-box-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{
                          $t("bulksms.campaign.simple.btn.detail")
                        }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <campaign-simple-view
      :dialog="dialog.view"
      :campaign="campaign.display"
    ></campaign-simple-view>
    <campaign-simple-detail
      :dialog="dialog.detail"
      :campaign="campaign.details"
    ></campaign-simple-detail>
    <campaign-simple-filter
      :dialog="dialog.filter"
      @filter="applyFilter"
    ></campaign-simple-filter>
  </div>
</template>

<script>
import CampaignSimpleView from "./simple-view.vue";
import CampaignSimpleDetail from "./simple-details.vue";
import CampaignSimpleFilter from "./simple-filter.vue";
import { mapActions } from "vuex";
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  metaInfo() {
    return {
      title: this.$t("bulksms.campaign.simple.list.title"),
    };
  },
  data: () => ({
    path: "/api/providers/bulksms/pushes/simple",
    dialog: {
      view: {
        display: false,
        loading: false,
      },
      detail: {
        display: false,
        loading: false,
      },
      filter: {
        display: false,
      },
    },
    campaign: {
      display: {},
      details: {},
    },
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("bulksms.campaign.fields.created.title"),
          value: "createdAt",
          align: "start",
          sortable: true,
          width: 130,
        },
        {
          text: this.$t("bulksms.campaign.fields.sender.title2"),
          value: "sender",
          align: "start",
          sortable: false,
          width: "80",
        },
        {
          text: this.$t("bulksms.campaign.fields.content.title2"),
          value: "sender",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.campaign.fields.status.title"),
          value: "status",
          align: "start",
          sortable: false,
          width: "80",
        },
        {
          text: this.$t("bulksms.campaign.fields.total.title"),
          align: "start",
          sortable: false,
          width: "80",
        },
        {
          text: this.$t("bulksms.campaign.fields.treated.title"),
          align: "start",
          sortable: false,
          width: "80",
        },
        {
          text: this.$t("bulksms.campaign.fields.treated.title2"),
          align: "start",
          sortable: false,
          width: "80",
        },
        {
          text: "",
          align: "start",
          width: 130,
          sortable: false,
        },
      ];
    },
    async getCampaign(id) {
      // reset push content
      this.campaign.display = {};

      this.dialog.view.loading = true;
      this.dialog.view.display = true;
      try {
        const response = await this.request({
          url: `/api/providers/bulksms/pushes/simple/${id}`,
        });
        this.campaign.display = response.data;
      } catch (error) {
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const data = error.response.data;
          if (data.details.code === 404) {
            message = "bulksms.campaign.error.not_found";
          }
        }
        this.notify({ message: this.$t(message) });
      }
      this.dialog.view.loading = false;
    },
    async getCampaignDetails(id) {
      // reset push content
      this.campaign.details = {};

      this.dialog.detail.loading = true;
      this.dialog.detail.display = true;
      try {
        const response = await this.request({
          url: `/api/providers/bulksms/pushes/simple/${id}/details`,
        });
        this.campaign.details = response.data;
      } catch (error) {
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const data = error.response.data;
          if (data.details.code === 404) {
            message = "bulksms.campaign.error.not_found";
          }
        }
        this.notify({ message: this.$t(message) });
      }
      this.dialog.detail.loading = false;
    },
    getFilter() {
      this.dialog.filter.display = true;
    },
    isScheduledPush(created, sendDate) {
      if (this.$moment(sendDate).isAfter(this.$moment(created))) {
        return true;
      } else {
        return false;
      }
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  components: {
    CampaignSimpleView,
    CampaignSimpleFilter,
    CampaignSimpleDetail,
  },
};
</script>