<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.campaign.simple.filter.title")
          }}</span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-2 mt-0 pb-0">
        <v-container fluid grid-list-md class="px-5">
          <v-text-field
            class="font-weight-bold"
            :placeholder="$t('bulksms.campaign.fields.sender.title2')"
            v-model="filters.sender.value"
            :label="$t('bulksms.campaign.fields.sender.title')"
            maxlength="11"
            clearable
            append-icon="mdi-account-arrow-right-outline"
            autocomplete="off"
          ></v-text-field>
          <v-dialog
            ref="dialog"
            v-model="daterange.dialog"
            :return-value.sync="daterange.value"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="font-weight-bold"
                v-model="dateRangeText"
                :label="$t('bulksms.campaign.fields.created.title3')"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="daterange.value" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="daterange.dialog = false">
                {{ $t("btn.cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(daterange.value)"
              >
                {{ $t("btn.ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-0 px-5">
        <v-spacer></v-spacer>
        <v-btn text @click="reset()">{{ $t("btn.reset") }}</v-btn>
        <v-btn
          text
          color="primary"
          class="font-weight-bold"
          @click="filter()"
          >{{ $t("btn.filter") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
  },
  data: () => ({
    daterange: {
      dialog: false,
      value: [null, null],
    },
    filters: {
      sender: {
        operator: "$contL",
        value: null,
      },
    },
  }),
  methods: {
    filter() {
      const filters = this.filters;
      if (
        this.daterange.value.length === 2 &&
        this.daterange.value.every((val) => val)
      ) {
        const [start, end] = this.$utils.daterangeAlign(this.daterange.value);
        filters.start = {
          field: "createdAt",
          operator: "$gte",
          value: start + " 00:00:00",
        };
        filters.end = {
          field: "createdAt",
          operator: "$lte",
          value: end + " 23:59:59",
        };
      }
      this.$emit("filter", filters);
      this.dialog.display = false;
    },
    reset() {
      this.$emit("filter", {});
      for(const prop in this.filters) {
        this.filters[prop].value = null
      }
      this.dialog.display = false;
    },
  },
  computed: {
    dateRangeText: {
      // getter
      get: function () {
        if (
          this.daterange.value.length === 2 &&
          this.daterange.value.every((val) => val)
        ) {
          const [start, end] = this.$utils.daterangeAlign(this.daterange.value);
          return `${start} - ${end}`;
        }
        return null;
      },
      // setter
      set: function () {
        this.daterange.value = [null, null]
      },
    },
  },
};
</script>