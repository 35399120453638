<template>
  <v-dialog v-model="dialog.display" scrollable max-width="500px">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">{{
            $t("bulksms.campaign.simple.display.title")
          }}</span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="dialog.loading === false" class="px-3">
        <v-container fluid grid-list-md v-if="!_.isEmpty(campaign)">
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-calendar-blank-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.created.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2"
                    ><span class="font-weight-bold">{{
                      $moment(campaign.createdAt).format("YYYY-MM-DD HH:mm:ss")
                    }}</span></span
                  >
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-account-arrow-right-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.sender.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2 font-weight-bold">{{
                    campaign.sender
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-radiobox-blank</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.status.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <template v-if="campaign.status === 'INIT'">
                  <v-chip
                    color="purple darken-4"
                    v-if="
                      isScheduledPush(campaign.createdAt, campaign.sendDate)
                    "
                    class="table-chips text-body-2 mr-0"
                  >
                    <span class="white--text">{{
                      $t("bulksms.campaign.fields.status.scheduled")
                    }}</span>
                  </v-chip>
                  <v-chip
                    color="secondary"
                    v-else
                    class="table-chips text-body-2 mr-0"
                  >
                    <span class="white--text">{{
                      $t("bulksms.campaign.fields.status.init")
                    }}</span>
                  </v-chip>
                </template>
                <v-chip
                  color="indigo darken-4"
                  class="table-chips text-body-2 mr-0"
                  v-else-if="campaign.status === 'RUNNING'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.running")
                  }}</span>
                </v-chip>
                <v-chip
                  color="success"
                  class="table-chips text-body-2 mr-0"
                  v-else-if="campaign.status === 'TRAITED'"
                >
                  <span class="white--text">{{
                    $t("bulksms.campaign.fields.status.treated")
                  }}</span>
                </v-chip>
                <v-chip
                  color="secondary"
                  class="table-chips text-body-2"
                  v-else
                >
                  <span class="white--text">-</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <template
            v-if="isScheduledPush(campaign.createdAt, campaign.sendDate)"
          >
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="8" class="py-2">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-clock-outline</v-icon>
                  <span class="text-body-1 font-weight-bold">{{
                    $t("bulksms.campaign.fields.send_date.title2")
                  }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="4" class="py-2">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="font-weight-bold">{{
                      $moment(campaign.sendDate).format("YYYY-MM-DD HH:mm")
                    }}</span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1"
                  >mdi-card-account-phone-outline</v-icon
                >
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.total.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold">{{
                    $utils.pad(campaign.totalNumber, 2)
                  }}</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-contacts-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.treated.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="fourth"
                  class="table-chips mr-0"
                  v-if="campaign.status !== 'INIT'"
                >
                  <span
                    class="font-weight-bold"
                    v-if="campaign.lastTreated <= campaign.totalNumber"
                    >{{
                      $utils.pad(
                        campaign.totalNumber - campaign.totalNotSend,
                        2
                      )
                    }}</span
                  >
                  <span class="font-weight-bold" v-else>{{
                    campaign.lastTreated
                  }}</span>
                </v-chip>
                <v-chip color="fourth" v-else class="table-chips mr-0">
                  -
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-contacts-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.treated.title2")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span
                    class="font-weight-bold"
                    v-if="campaign.status !== 'INIT'"
                    >{{ campaign.totalNotSend }}</span
                  >
                  <span class="font-weight-bold" v-else>-</span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-message-text-outline</v-icon>
                <span class="text-body-1 font-weight-bold">{{
                  $t("bulksms.campaign.fields.sms_size.title")
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="font-weight-bold"
                    >{{ $utils.pad(campaign.contentCost, 2) }} SMS</span
                  >
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="12">
              <div
                class="text-justify font-weight-bold grey--text text--darken-2"
              >
                {{ campaign.content }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center mt-5">
          <v-progress-circular
            indeterminate
            color="secondary"
            width="3"
            size="50"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
          loading: true,
        };
      },
    },
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  methods: {
    isScheduledPush(created, sendDate) {
      if (this.$moment(sendDate).isAfter(created)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>